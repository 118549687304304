import React, { useMemo, useState } from 'react';
import { Pie, Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Paper, Typography, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { COLORS } from '../../util/constants';
import { styles, getChartOptions, getPieChartOptions } from '../../css/charts.styles';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const ConnectionCharts = ({ players, selectedOrgName,allFlag, summary }) => {
  const chartHeight = summary ? 200 : 350;
  const [chartType, setChartType] = useState('doughnut'); // Default chart type
  
  const calculateDaysOffline = (lastSeen) => {
    const lastSeenDate = new Date(lastSeen);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - lastSeenDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const getConnectionStats = useMemo(() => {
    let filteredPlayers = players;
    if(summary)
      filteredPlayers=players.players;
    //console.log(filteredPlayers)
    const totalPlayers = filteredPlayers && filteredPlayers.length;
    // const connectedPlayers = filteredPlayers.filter(player => player.isConnected).length;
    // const offlinePlayers = filteredPlayers.filter(player => !player.isConnected);
    // const offlineMoreThanWeek = offlinePlayers.filter(player => !player.isConnected && calculateDaysOffline(player.lastSeen) > 7).length;
    // const offlineWeekOrLess = offlinePlayers.filter(player => !player.isConnected && calculateDaysOffline(player.lastSeen) <= 7).length;

    const {
      connectedPlayers = 0,
      offlineMoreThanWeek = 0,
      offlineWeekOrLess = 0,
      offlinePlayers = 0,
    } = (filteredPlayers || []).reduce((acc, player) => {
      if (player.isConnected) {
        acc.connectedPlayers += 1; // Count connected players
      } else {
        const daysOffline = calculateDaysOffline(player.lastSeen);
        acc.offlinePlayers += 1; // Count offline players
        if (daysOffline > 7) {
          acc.offlineMoreThanWeek += 1; // Count offline players more than a week
        } else {
          acc.offlineWeekOrLess += 1; // Count offline players a week or less
        }
      }
      return acc;
    }, { connectedPlayers: 0, offlineMoreThanWeek: 0, offlineWeekOrLess: 0, offlinePlayers:0 });

    return { totalPlayers, connectedPlayers,offlinePlayers, offlineMoreThanWeek, offlineWeekOrLess };
  }, [players, selectedOrgName]);

  const { totalPlayers, connectedPlayers,offlinePlayers, offlineMoreThanWeek, offlineWeekOrLess } = getConnectionStats;
  const labels = [];
  const data = [];
  if(allFlag){
    labels.push('Online', 'Offline')
    data.push(connectedPlayers, offlinePlayers)
  }else{
    labels.push('Online', 'Offline > 1 week', 'Offline ≤ 1 week');
    data.push(connectedPlayers, offlineMoreThanWeek, offlineWeekOrLess)
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: [COLORS.pieGreen, COLORS.pieRed, COLORS.pieYellow],
        borderColor: [COLORS.pieGreen, COLORS.pieRed, COLORS.pieYellow],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Players',
        data: data,
        backgroundColor: [COLORS.pieGreen, COLORS.pieRed, COLORS.pieYellow],
      },
    ],
  };

  const chartOptions = getChartOptions();
  const pieChartOptions = getPieChartOptions();


  return (
    <Paper elevation={3} sx={{ padding: 2, borderRadius: 2,  }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        

        <Box sx={{ width: '100%', maxWidth: 600, height: chartHeight }}>
          {chartType === 'doughnut' && <Doughnut data={chartData} options={chartOptions} />}
          {chartType === 'pie' && <Pie data={chartData} options={pieChartOptions} />}
          {chartType === 'bar' && <Bar data={barChartData} options={chartOptions} />}
        </Box>
      </Box>
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        <Typography sx={styles.totalPlayers}>Total Players: {totalPlayers}</Typography>
        <Typography sx={styles.connectedPlayers}>Online Players: {connectedPlayers}</Typography>
        {allFlag ?
          <Typography sx={styles.disconnectedPlayers}>Offline Players: {offlinePlayers}</Typography> :
          <>
            <Typography sx={styles.disconnectedPlayers}>Offline > 1 week: {offlineMoreThanWeek}</Typography>
            <Typography sx={styles.disconnectedPlayers2}>Offline ≤ 1 week: {offlineWeekOrLess}</Typography>
          </>
        }
      </Box>
    </Paper>
  );
};

export default ConnectionCharts;