import { COLORS, COLORS1 } from '../util/constants';

export const styles = {
  tabsContainer: {
    borderBottom: 1,
    borderColor: 'divider',
    bgcolor: COLORS1.tabColor,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
  },
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    color: COLORS.darkGrey,
    fontWeight: 'bold',
    '&.Mui-selected': {
      color: COLORS.whiteSmoke,
      bgcolor: COLORS1.tabSelected,
      borderTop: `2px solid ${COLORS1.tabBorder}`,
      borderLeft: `2px solid ${COLORS1.tabBorder}`,
      borderRight: `2px solid ${COLORS1.tabBorder}`,
      borderBottom: `2px solid ${COLORS1.tabColor}`,
    },
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    margin: '0 2px',
    transition: 'all 0.2s',
    '&:hover': {
      bgcolor: COLORS1.tabHoverColor,
      color: COLORS.charcoal,
    },
  },
  tabPanelContainer: {
  
  },
  tabPanel: {
    bgcolor: COLORS.white,
    paddingTop:'15px !important',
  },
  playerTableGrid: {
    padding: '10px !important',
    
  },
  tabPanelPlayers:{
    padding:'0px',
    
  },
  logoutContainer: {
    paddingTop: '10px',
    paddingRight: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logoutButton: {
    fontWeight: 'bold',
   // alignItems: 'flex-end',
    color: COLORS.charcoal,
    bgcolor: COLORS1.tabSelected,
    '&:hover': {
      bgcolor: COLORS1.tabHoverColor,
      color: COLORS.whiteSmoke,
    },
  },

  
};