import { COLORS, COLORS1 } from '../util/constants';

const styles = {
    container: {
        p: 0,
        backgroundColor: COLORS1.bgColor,
        minHeight: "100vh",
    },
    heading: {
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: COLORS1.mustardYellow,
        overflow: 'hidden',
        //padding: '2px',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        maxHeight:'5vh'
    },
    row1Grids:{
      maxHeight: "320px",
      minHeight: "250px",
      //paddingTop: '3px'
     
      
        //overflowY:'auto',
    },
    players:{
        maxHeight: "320px",
        minHeight: "250px",
        
    },
    table: {
        mt: 2,
        padding: '1px',
        overflowY:'auto',
        minHeight: "250px"
    },
    playerStatus:{
      minHeight:'250px'
    },

    mainGrid: {
        //bgcolor: COLORS.whiteSmoke,

    },

    gridStyles: {
        //padding: '1px 3px',
        bgcolor: COLORS.whiteSmoke,
        width:'100%',
        margin:"0px",
        paddingRight:"15px"
    },

    header: {
        mb: 3,
        textAlign: "center",
        fontWeight: "bold",
    },
    paper: {
        p: 2,
        textAlign: "center",
        backgroundColor: "#ffffff",
        boxShadow: 3,
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
       
    },

    linkBox: {
        mt: 4,
        textAlign: "center",
    },
    link: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "primary.main",
        cursor: "pointer",
        textDecoration: "underline",
        "&:hover": {
            color: "secondary.main",
        },
    },
    table: {
      minWidth: {
        xs: '100%',  // Extra small screens
        sm: '1500px',  // Small screens
        md: '150px',  // Medium screens
        lg: '200px',  // Large screens
        xl: '250px',  // Extra large screens
    },
    minHeight: {
        xs: '200px',  // Extra small screens
        sm: '250px',  // Small screens
        md: '250px',  // Medium screens
        lg: '300px',  // Large screens
        xl: '350px',  // Extra large screens
    },
    maxHeight: {
        xs: '250px',  // Extra small screens
        sm: '300px',  // Small screens
        md: '350px',  // Medium screens
        lg: '400px',  // Large screens
        xl: '450px',  // Extra large screens
    },
        width: '100%',
        tableLayout: 'fixed'
      },
      tableContainer:{
        maxHeight: {
          xs: '200px',  // Extra small screens
          sm: '250px',  // Small screens
          md: '300px',  // Medium screens
          lg: '350px',  // Large screens
          xl: '400px',  // Extra large screens
      },
        overflowY: 'auto'
      },
      tableHead: {
        '& .MuiTableCell-head': {
          backgroundColor: COLORS1.bgColor + ' !important',
        },
        top: 0, 
        background: 'white', 
        zIndex: 2 
      },
      tableHeadCell: {
        color: COLORS.whiteSmoke,
        whiteSpace: 'nowrap',
        fontSize: '0.9rem',
        width: '20%',
        fontWeight: 'bold',
        height: '5%',
      },
      tableSortLabel: {
        color: COLORS.whiteSmoke + ' !important',
        '&.MuiTableSortLabel-active': {
          color: COLORS.whiteSmoke + ' !important',
        },
        '&:hover': {
          color: COLORS.lightGrey + ' !important',
        },
        '& .MuiTableSortLabel-icon': {
          color: COLORS.whiteSmoke + ' !important',
        },
      },
      tableRow: {
        '&:nth-of-type(even)': {
          backgroundColor: COLORS1.beige,
        },
        '&:nth-of-type(odd)': {
          backgroundColor: COLORS.whiteSmoke,
        },
        '&:hover': {
          backgroundColor: COLORS.hoverRed,
          '& .MuiTableCell-root': { color: COLORS.black },
        },
      },
      tableCell: {
        whiteSpace: 'wrap',
        color: COLORS.black,
        padding: '8px',
        fontSize: '0.8rem',
      },
      iconCell: {
        textAlign: 'center',
      },
      nameCell: {
        whiteSpace: 'normal', // Allow text to wrap
        wordWrap: 'break-word', // Break long words
        color: COLORS.black,
        padding: '8px',
        maxWidth: '150px',
        overflow: 'hidden'
      },
      tableBody: {
        overflow: 'auto',
      },
      connectedCell: {
        color: COLORS.seaGreen,
      },
      disconnectedCell: {
        color: COLORS.crimson,
      },
      loadingSpinner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      },
      errorMessage: {
        color: COLORS.crimson,
        textAlign: 'center',
        marginTop: 2,
      },
      playerTableGrid: {
        padding: '0px !important',
        height: '100%',
        width:'100%',
    
      },
      fontSize: {
        xs: '0.65rem', // Font size for extra small screens
        sm: '0.75rem', // Font size for small screens
        md: '0.8rem', // Font size for medium screens
        textAlign: 'center',

    },
    headingFont: {
      pl: 2, 
      fontSize: '0.8rem', 
      fontWeight: 'bold' ,
    },
    header:{
      bgcolor: COLORS1.bgColor,
      borderBottom: 1,
      borderColor: 'divider',
      overflow: 'hidden',
      //padding: '2px',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      maxHeight:'5vh',
      color: COLORS.whiteSmoke,
     
    },
    qrCodeText: {
      borderBottom: 1,
      borderColor: 'divider',
      bgcolor: COLORS1.mustardYellow,
      overflow: 'hidden',
      //padding: '2px',
      borderRadius: 5,
      maxHeight:'5vh',
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
  },
};

export default styles;
