import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Box,
  Grid,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import '../../css/PlayersTable.css';
import { styles } from '../../css/players.styles';
import Modal from './PlayerDetailsModal'
import { getPlayerIcon } from '../../util/dashboardUtil';

const PlayersTable = ({ players, orgNames, loading, error, selectedOrgName, setSelectedOrgName,allFlag }) => {
  //console.log(players)
  const [sortConfig, setSortConfig] = useState({ key: 'daysOffline', direction: 'desc' });
  const [sortedPlayers, setSortedPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tableRef = useRef(null);

  const handleRowClick = (player) => {
    setSelectedPlayer(player);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPlayer(null);
  };




  useEffect(() => {
    let sortedData = [...players];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (sortConfig.key === 'daysOffline') {
          const aDays = a.isConnected ? 0 : a.offLineDays;
          const bDays = b.isConnected ? 0 : b.offLineDays;
          if (aDays < bDays) return sortConfig.direction === 'asc' ? -1 : 1;
          if (aDays > bDays) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        }
        // Add more sorting logic for other columns if needed
        return 0;
      });
    }
    setSortedPlayers(sortedData);
  }, [players, sortConfig]);

  const handleSort = () => {

    setSortConfig(prevConfig => ({
      key: 'daysOffline',
      direction: prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));

    //console.log(sortConfig)
  };



  if (loading) {
    return <Box sx={styles.loadingSpinner}><CircularProgress /></Box>;
  }
  if (error) return <Typography sx={styles.errorMessage}>{error}</Typography>;
  if (players.length === 0) return <Typography sx={styles.errorMessage}>No Players Found</Typography>;
  
  return (
    <Grid   sx={styles.playerTableGrid}>
      <TableContainer ref={tableRef} sx={styles.tableContainer}>
        <Table stickyHeader sx={styles.table} >
          <TableHead sx={styles.tableHead}>
            <TableRow>
              {allFlag && <TableCell sx={styles.tableHeadCell}>Player Type</TableCell>}
              <TableCell sx={styles.tableHeadCell}>Name</TableCell>
              <TableCell sx={styles.tableHeadCell}>Client</TableCell>
              <TableCell sx={styles.tableHeadCell}>Connected</TableCell>
              <TableCell sx={styles.tableHeadCell}>
                <TableSortLabel
                  active={sortConfig.key === 'daysOffline'}
                  direction={sortConfig.direction}
                  sx={styles.tableSortLabel}
                  onClick={handleSort}
                />
                  Days Offline
                
              </TableCell>
              
             
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPlayers.map((player) => (
              <TableRow onClick={() => handleRowClick(player)} key={player._id || player.id} sx={styles.tableRow}>
                 {allFlag && <TableCell sx={[styles.tableCell, styles.iconCell]}>{player.playerType.iconPath}
                 </TableCell>}
                 <TableCell sx={styles.nameCell}>{player.name || 'N/A'}</TableCell>
                <TableCell sx={styles.tableCell}>{player.orgName || 'N/A'}</TableCell>
                <TableCell sx={player.isConnected ? styles.connectedCell : styles.disconnectedCell}>
                  {player.isConnected ? 'Yes' : 'No'}
                </TableCell>
                  <TableCell sx={styles.tableCell}>
                    {player.offLineDays}
                  </TableCell>
                 
              </TableRow>

            ))}
          </TableBody>
        </Table>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} player={selectedPlayer} />

      </TableContainer>
    </Grid>

  );
};

export default PlayersTable;