import React, {useEffect} from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styles } from '../../css/players.styles';
import { getUniqueValues } from '../../util/dashboardUtil';





const TicketFilters = ({ tickets, clientFilter, setClientFilter, agentFilter, setAgentFilter }) => {
  //console.log(setClientFilter)
  // Get unique client and agent names for the dropdowns
  const uniqueClients = getUniqueValues(tickets, "clientName");
  const uniqueAgents = getUniqueValues(tickets, "agentName");

  useEffect(() => {
    const savedClient = sessionStorage.getItem('selectedClientTicket');
   
    if (savedClient !== null) {
      setClientFilter(savedClient); // Set the selected player type from sessionStorage
    }
    const savedAgent = sessionStorage.getItem('selectedAgent');
    
    if (savedAgent !== null) {
        
        setAgentFilter(savedAgent); // Set the selected player type from sessionStorage
    }
    
}, [setClientFilter, setAgentFilter]);

const handleFilterChange = (e, type) => {
  const value = e;
  console.log(type)
  if (type === 1) {
    setClientFilter(value); // Update state
      sessionStorage.setItem('selectedClientTicket', value);
  } else if (type === 2) {
    setAgentFilter(value); // Update state
    sessionStorage.setItem('selectedAgent', value);
  }// Store in sessionStorage
};

  return (
    <Grid container spacing={2} sx={styles.filtersContainer}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth sx={styles.formControl}>
          <InputLabel id="client-name-select-label" sx={styles.inputLabel}>Filter by Client</InputLabel>
          <Select
            labelId="client-name-select-label"
            id="client-name-select"
            value={clientFilter}
            label="Filter by Client"
            onChange={(e) => handleFilterChange(e.target.value, 1)}
            sx={styles.select}
          >
            <MenuItem value="">
              <em>All Clients</em>
            </MenuItem>
            {uniqueClients.map((client) => (
              <MenuItem key={client} value={client}>{client}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth sx={styles.formControl}>
          <InputLabel id="agent-status-select-label" sx={styles.inputLabel}>Filter by Agent</InputLabel>
          <Select
            labelId="agent-status-select-label"
            id="agent-status-select"
            value={agentFilter}
            label="Filter by Agent"
            onChange={(e) => handleFilterChange(e.target.value,2)}
            sx={styles.select}
          >
            <MenuItem value="">
              <em>All Agents</em>
            </MenuItem>
            {uniqueAgents.map((agent) => (
              <MenuItem key={agent} value={agent}>{agent}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TicketFilters;