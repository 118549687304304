import { COLORS1 } from '../util/constants';

export const styles = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    bgcolor: COLORS1.bgColor,
 
  },
  container: {
   marginTop: '20px',
   marginBottom: '20px',
 
  },
};