
import { determinePlayerType, getWorkingDays, convertUtcToTimezone, processFetchedData, calculateDaysOffline, getUniqueValues } from '../util/dashboardUtil';
import { fetchPlayers, fetchTickets, fetchCurrCheckin, fetchTicketsSummary, fetchOfflinePlayers } from '../services/api';
import { anamolies } from '../util/anamolies';
import { orgIds } from '../util/constants';
import { clearDatabase, loadDataFromDB, deleteDatabase, safeUpdateSummary } from '../localdb/PouchDB';
import { tickets_db, players_db, curr_checkin_db } from '../localdb/PouchDB'


export const getCurrentCheckin = async () => {
    try {
        const response = await fetchCurrCheckin();
        const currCheckin = { ...response[0],
            lastUpdated: new Date().toLocaleString(),
            id:0}
        return currCheckin;

    } catch (err) {
        console.log("Current Checkin - ",err.message)
        if (err.message === 'Network Error') {
            const checkin = await  loadDataFromDB(curr_checkin_db)
            return checkin[0]
        }
        
    }
}

export const getRecentTickets = async () => {
    try {
        const allTickets = await fetchTicketsSummary();
        const fetchedTickets = {
            openNum: allTickets.openTicketCount,
            closedNum: allTickets.closedTicketCount,
            recentTickets: allTickets.recentTickets.map(ticket => ({
                ticketNumber: ticket.ticketNumber,
                clientName: ticket.clientName,
                subject: ticket.subject,
                agentName: ticket.agentName
            })),
            lastUpdated: new Date().toLocaleString()
        };
        return fetchedTickets

    } catch (err) {
        console.log("Tickets - ",err.message)
        if (err.message === 'Network Error') {
            const tickets = await  loadDataFromDB(tickets_db)
            return tickets[0]
        }
    }
}

export const getOfflinePlayers = async () => {
    try {
        const allPlayers = await fetchOfflinePlayers();
        const fetchedPlayers = {
            total: allPlayers.totalPlayers,
            exterior: allPlayers.exteriorPlayers,
            interior: allPlayers.interiorPlayers,
            mobile: allPlayers.mobilePlayers,
            offLinePlayers: allPlayers.recentlyOffPlayers.map(player => ({
                name: player.name,
                orgName: player.orgName,
                orgType: player.orgType
            })),
            fullyManagedOffline: allPlayers.recOffFullyManagedPlayers
                .map(player => ({ 
                    name: player.name,
                    orgName: player.orgName,
                    lastSeen: convertUtcToTimezone(player.timezone, player.lastSeen)
                })),
            lastUpdated: new Date().toLocaleString()
        }
        
        return fetchedPlayers;

    } catch (err) {
        console.log("Players- ",err.message)
        if (err.message === 'Network Error') {
            const players =  await  loadDataFromDB(players_db)
            return players[0]
        }
    }
}

