import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Button, Typography } from '@mui/material';
import TicketsDashboard from './Tickets/TicketsDashboard';
import PlayersDashboard from './Players/PlayersDashboard';
import { determinePlayerType, getWorkingDays, getClientNamesByEmail, processFetchedData, calculateDaysOffline } from '../util/dashboardUtil';
import { styles } from '../css/dashboard.styles';
import { fetchPlayers, fetchTickets } from '../services/api';
import { anamolies } from '../util/anamolies';
import { orgIds } from '../util/constants';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout'


function Dashboard({ onLogout }) {
  const [value, setValue] = useState(0);
  const [players, setPlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [validOrgIds, setValidOrgIds] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tickets, setTickets] = useState({ tickets: 0, openTickets: 0, closedTickets: 0 });
  const [loadingTickets, setLoadingTickets] = useState(true);
  const [errorTickets, setErrorTickets] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear session storage
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('userEmail');
    sessionStorage.removeItem('isAuthenticated');
    onLogout();
    // Redirect to the login page
    navigate('/');
  };



  useEffect(() => {

    const storedValue = Number(sessionStorage.getItem('dashboardTab')) || 0; // Retrieve stored tab index
    setValue(storedValue); // Set the initial tab value
    getTickets();
    const interval = setInterval(() => {
      getTickets();
    }, 300000); // Poll every minute

    return () => clearInterval(interval);
  }, []);

  const getTickets = async () => {
    try {
      setLoadingTickets(true);
      const response = await fetchTickets();
      let fetchedTickets = processFetchedData(response);
      //console.log(fetchedTickets)
      //console.log(fetchedTickets.length)

      let filteredTickets = await getClientNamesByEmail(fetchedTickets);
      //console.log(filteredTickets)
      // filter for only Birmingham clients
      // let filteredTickets = fetchedTickets.filter(ticket => 
      //   clientNamesArr.includes(ticket.clientName)
      // );

      let openTickets = filteredTickets.filter(ticket => ticket.status === 'Open').sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
      let closedTickets = filteredTickets.filter(ticket => ticket.status === 'Closed').sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
      //console.log("Open tickets" + openTickets.length)
      //console.log("Closed tickets" + closedTickets.length)
      //const allOpenTickets = filterAndSortTickets(filteredTickets);
      //console.log(filteredTickets)

      setTickets({ tickets: filteredTickets, openTickets: openTickets, closedTickets: closedTickets })

    } catch (err) {
      setError('An error occurred while fetching tickets');
      setTickets([]);
    } finally {
      setLoadingTickets(false);
    }
  };

  const handleChange = (event, newValue) => {
    sessionStorage.setItem('dashboardTab', newValue); // Store the selected tab index
    setValue(newValue);
  }

  useEffect(() => {

    loadPlayers();
    const interval = setInterval(() => {
      loadPlayers();
      sessionStorage.removeItem("nowOnlinePlayers");
      setCurrentTime(new Date().toLocaleTimeString());
    }, 300000); // Poll every 5 mins



    return () => clearInterval(interval);

  }, []);
  const loadPlayers = async () => {
    try {
      setLoading(true);
      const response = await fetchPlayers();

      const fetchedPlayers = processFetchedData(response);
      //console.log(fetchedPlayers)
      // Update orgName to client if available
      const updatedPlayers = await Promise.all(fetchedPlayers.map(async player => { // Make the map function async

        const updatedPlayer = {
          ...player,
          orgName: player.client || player.orgName,
          offLineDays: player.isConnected ? 0 : calculateDaysOffline(player.lastSeen),
          playerType: determinePlayerType(player),
          workingDays: getWorkingDays(player),
          //orgType:orgType
        };
        if (anamolies[player.id]) {
          // Apply anomaly if exists
          updatedPlayer.orgName = anamolies[player.id].org;
        }
        return updatedPlayer;
      }));
      setAllPlayers(updatedPlayers)


      // Filter players to include only those from specified orgIds
      const validOrgIds = Object.values(orgIds);
      setValidOrgIds(validOrgIds)
      const filteredPlayers = updatedPlayers.filter(
        player => player && typeof player === 'object' && validOrgIds.includes(player.orgId)
      );


      setPlayers(filteredPlayers);
      //console.log(filteredPlayers)

    } catch (err) {
      console.error('Error fetching players:', err);
      setError('Failed to load players. Please try again later.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={styles.tabsContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={styles.tabs}
        >
          <Tab label="Tickets" sx={styles.tab} disableRipple />
          <Tab label="All Players" sx={styles.tab} disableRipple />
          <Tab label="Birmingham Players" sx={styles.tab} disableRipple />
        </Tabs>
        <Button sx={styles.logoutButton} onClick={handleLogout}> <LogoutIcon /></Button>
      </Box>

      <Box sx={styles.tabPanelContainer}>
        <TabPanel value={value} index={0} sx={styles.tabPanel}>
          <TicketsDashboard tickets={tickets} loading={loadingTickets} error={errorTickets} />
        </TabPanel>
        <TabPanel value={value} index={1} sx={styles.tabPanel}>
          <PlayersDashboard players={allPlayers} loading={loading} error={error} allFlag={true} currentTime={currentTime} />
        </TabPanel>
        <TabPanel value={value} index={2} sx={styles.tabPanel}>
          <PlayersDashboard players={players} loading={loading} error={error} allFlag={false} currentTime={currentTime} />
        </TabPanel>
      </Box>

    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Dashboard;