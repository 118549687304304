import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Box, Container } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { COLORS } from './util/constants';
import { styles } from './css/app.styles';



const theme = createTheme({
  palette: {
    background: {
      default: COLORS.grey,
    },
  },
});

const App = () => {
  console.log("this is the app")
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Effect to check session storage for authentication status
  useEffect(() => {
    const authStatus = sessionStorage.getItem('isAuthenticated');
    console.log(authStatus)
    setIsAuthenticated(authStatus === 'true'); // Convert string to boolean
  }, []);
  console.log(isAuthenticated)
  const handleLogin = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem('isAuthenticated', 'true'); // Store authentication status in session storage
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem('isAuthenticated'); // Remove authentication status from session storage
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={styles.app}>
        <Box sx={styles.container}>
          <Router>
            <Routes>
              <Route path="/" element={<Login onLogin={handleLogin}/>} /> 
              <Route path="/login" element={<Login onLogin={handleLogin}/>} /> 
              <Route
                path="/dashboard"
                element={isAuthenticated ? <Dashboard onLogout={handleLogout} /> : <Navigate to="/" />}
              />  {/* Route for Dashboard */}
            </Routes>
          </Router>
        </Box>


      </Box>
    </ThemeProvider>
  );
}

export default App;
