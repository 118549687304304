import React, { useEffect } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styles } from '../../css/players.styles';
import { getUniqueValues } from '../../util/dashboardUtil';

const PlayerFilters = ({ orgNames, players,selectedOrgName, setSelectedOrgName, selectedPlayerType, setSelectedPlayerType, selectedTimezone, setSelectedTimezone, allFlag }) => {
  const timeZones = getUniqueValues(players,"timezone")
  useEffect(() => {
    
    
    if(allFlag){
      const savedOrgName = sessionStorage.getItem('selectedOrgName');
      if (savedOrgName !== null ) {
        setSelectedOrgName(savedOrgName); // Set the selected player type from sessionStorage
      }
      const savedPlayerType = sessionStorage.getItem('selectedPlayerType');
      console.log(savedPlayerType)
      if (savedPlayerType !== null) {
          console.log("savedPlayerType")
          setSelectedPlayerType(savedPlayerType); // Set the selected player type from sessionStorage
      }
      const savedTimeZone = sessionStorage.getItem('selectedTimezone');
      if (savedTimeZone) {
          setSelectedTimezone(savedTimeZone); // Set the selected player type from sessionStorage
      }
    }else{
      setSelectedOrgName(sessionStorage.getItem('selectedOrgNameBHM'))
      setSelectedPlayerType('')
      setSelectedTimezone('')
    }
    
}, [setSelectedPlayerType, setSelectedOrgName, setSelectedTimezone]);

const handleFilterChange = (e, type) => {
  const value = e;
  console.log(type)
  if (type === 1) {
      setSelectedOrgName(value); // Update state
      if(allFlag){
        sessionStorage.setItem('selectedOrgName', value);
      }else{
        sessionStorage.setItem('selectedOrgNameBHM', value);
      }
  } else if (type === 2) {
      setSelectedPlayerType(value); // Update state
      sessionStorage.setItem('selectedPlayerType', value);
  } else if (type === 3) {
      setSelectedTimezone(value); // Update state
      sessionStorage.setItem('selectedTimezone', value);
  }// Store in sessionStorage
};
  
  return (
    <Grid container spacing={2} sx={styles.filtersContainer}>
      <Grid item xs={12} md={6} >
        <FormControl fullWidth sx={styles.formControl}>
          <InputLabel id="org-name-select-label" sx={styles.inputLabel}>All Clients</InputLabel>
          <Select
            labelId="org-name-select-label"
            id="org-name-select"
            value={selectedOrgName}
            label="Filter by Organization Name"
            onChange={(e) => handleFilterChange(e.target.value,1)}
            sx={styles.select}
          >
            <MenuItem value="">
              <em>All Clients</em>
            </MenuItem>
            {orgNames.map((orgName) => (
              <MenuItem key={orgName} value={orgName}>{orgName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {allFlag ?
        <>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth sx={styles.formControl}>
              <InputLabel id="player-type-select-label" sx={styles.inputLabel}>All Player Types</InputLabel>
              <Select
                labelId="player-type-select-label"
                id="player-type-select"
                value={selectedPlayerType}
                label="Filter by Player Type"
                onChange={(e) => handleFilterChange(e.target.value,2)}
                sx={styles.select}
              >
                <MenuItem value="">
                  <em>All Player Types</em>
                </MenuItem>
                <MenuItem value="Mobile Player">Mobile</MenuItem>
                <MenuItem value="Exterior Player">Exterior</MenuItem>
                <MenuItem value="Interior Player">Interior</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth sx={styles.formControl}>
              <InputLabel id="timezone-select-label" sx={styles.inputLabel}>All Timezones</InputLabel>
              <Select
                labelId="timezone-select-label"
                id="timezone-select"
                value={selectedTimezone}
                label="Filter by Timezone"
                onChange={(e) => handleFilterChange(e.target.value, 3)}
                sx={styles.select}
              >
                <MenuItem value="">
                  <em>All Timezones</em>
                </MenuItem>
                {timeZones.map((timezone) => (
                  <MenuItem key={timezone} value={timezone}>{timezone}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>

        : <> </>
      }


    </Grid>
  );
};

export default PlayerFilters;