import React, { useState, useEffect, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Typography,
    Box,
    Grid,
    TableSortLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import '../../css/PlayersTable.css';
import styles from '../../css/summary.styles';


const PlayersTableForSummary = ({ players, type, loading, error, allFlag }) => {
    //console.log(players)
    const [playersSummary, setPlayersSummary] = useState({})
    const tableRef = useRef(null);
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);


    useEffect(() => {
        //const playersTemp = players && players.players
        //console.log(playersTemp)
        //console.log(oneWeekAgo)
        const playersToShow =  players &&  players.players && players.length !== 0 && players.players
            .filter(player => {
                //console.log(player, oneWeekAgo)
                if (type === 'Fully-Managed') {
                    return !player.isConnected && player.orgType === type && new Date(player.lastSeen) >= oneWeekAgo;
                }
                return !player.isConnected && new Date(player.lastSeen) >= oneWeekAgo;
            })
            .sort((a, b) => new Date(b.lastSeen) - new Date(a.lastSeen));
        //console.log(playersToShow)

        setPlayersSummary(players)

    }, [players]);



    if (loading) {
        return <Box sx={styles.loadingSpinner}><CircularProgress /></Box>;
    }
    if (error) return <Typography sx={styles.errorMessage}>{error}</Typography>;
    if (!players) return <Typography sx={styles.errorMessage}>No Players Found</Typography>;

    return (
        <Grid sx={styles.playerTableGrid}>
            <TableContainer ref={tableRef} sx={styles.tableContainer}>
                <Table stickyHeader sx={styles.table} >
                    <TableHead sx={styles.tableHead}>
                        <TableRow>
                            {allFlag && <TableCell sx={styles.tableHeadCell}>Player Type</TableCell>}
                            <TableCell sx={styles.tableHeadCell}>Name</TableCell>
                            <TableCell sx={styles.tableHeadCell}>Client</TableCell>
                            {type === 'Fully-Managed' && (
                                <TableCell sx={styles.tableHeadCell}>
                                    Last Seen 
                                </TableCell>
                            )}

                        </TableRow>
                    </TableHead>
                    <TableBody sx={styles.tableBody}>
                        {players &&
                            players.map((player) => (
                                <TableRow key={player._id || player.id} sx={styles.tableRow}>
                                    {allFlag && <TableCell sx={[styles.tableCell, styles.iconCell]}>{player.playerType.iconPath}
                                    </TableCell>}
                                    <TableCell sx={styles.nameCell}>{player.name || 'N/A'}</TableCell>
                                    <TableCell sx={styles.tableCell}>{player.orgName || 'N/A'}</TableCell>
                                    {type === 'Fully-Managed' && (
                                        <TableCell sx={styles.tableCell}>
                                            {player.lastSeen}
                                        </TableCell>
                                    )}


                                </TableRow>

                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

    );
};

export default PlayersTableForSummary;
