import React, { useState, useEffect, useMemo } from 'react';
import { filterPlayersByStatus } from '../../util/dashboardUtil';
import { Tabs, Tab, Box, CircularProgress } from '@mui/material';
import { styles } from '../../css/dashboard.styles';
import PlayersTable from './PlayersTable';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

const PlayersTab = ({ players, orgNames, loading, error, allFlag }) => {

    const [activeTab, setActiveTab] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'daysOffline', direction: 'desc' });

    // Load the active tab from sessionStorage on component mount
    useEffect(() => {
        const savedTab = sessionStorage.getItem('activeTab');
        if (savedTab) {
            setActiveTab(Number(savedTab)); // Set the active tab from sessionStorage
        }
    }, []);

    const sortedFilteredPlayers = useMemo(() => {

        return players.sort((a, b) => {
            const daysOfflineA = a.isConnected ? 0 : a.offLineDays;
            const daysOfflineB = b.isConnected ? 0 : b.offLineDays;

            if (sortConfig.key === 'daysOffline') {
                return sortConfig.direction === 'asc' ? daysOfflineA - daysOfflineB : daysOfflineB - daysOfflineA;
            }
            return 0;
        });
    }, [players, sortConfig]);


    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        sessionStorage.setItem('activeTab', newValue);
    };

    const filteredPlayers = filterPlayersByStatus(sortedFilteredPlayers, activeTab, allFlag);
    //console.log(filteredPlayers)
    return (
        <Box >
            <Box sx={styles.tabsContainer}>
                <Tabs value={activeTab} onChange={handleChange} aria-label="player status tabs" sx={styles.tabs}>
                    <Tab label="Offline > week" sx={styles.tab} disableRipple />
                    <Tab label="Offline ≤  week" sx={styles.tab} disableRipple />
                    <Tab label="Online" sx={styles.tab} disableRipple />
                </Tabs>
            </Box>
            <Box >
                <TabPanel value={activeTab} index={0} sx={styles.tabPanelPlayers} className='custom-table'>
                    {filteredPlayers || filteredPlayers.length > 0 ?
                        <PlayersTable players={filteredPlayers} orgNames={orgNames} loading={loading} error={error} sx={styles.playerTableGrid} />
                        :
                        <CircularProgress />
                    }
                </TabPanel>
                <TabPanel value={activeTab} index={1} sx={styles.tabPanelPlayers} className='custom-table'>
                    {filteredPlayers || filteredPlayers.length > 0 ?
                        <PlayersTable players={filteredPlayers} orgNames={orgNames} loading={loading} error={error} sx={styles.playerTableGrid} allFlag={allFlag} />
                        :
                        <CircularProgress />
                    }
                </TabPanel>
                <TabPanel value={activeTab} index={2} sx={styles.tabPanelPlayers} className='custom-table'>
                    {filteredPlayers || filteredPlayers.length > 0 ?
                        <PlayersTable players={filteredPlayers} orgNames={orgNames} loading={loading} error={error} sx={styles.playerTableGrid} allFlag={allFlag} />
                        :
                        <CircularProgress />
                    }
                </TabPanel>

            </Box>

        </Box>
    );
};

export default PlayersTab;