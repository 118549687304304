import { COLORS,COLORS1 } from '../util/constants';

export const styles = {
    
    popup: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '300px', // Adjust width as needed
        maxHeight: '400px', // Set a max height for the scrollbar
        overflowY: 'auto', // Enable vertical scrollbar
        backgroundColor: COLORS1.mustardYellow,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        zIndex: 1000, // Ensure it appears above other elements
    },
    popupContent: {
        padding: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    popupHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: COLORS1.black,
        paddingLeft: 1,
    },
    
    popupContentP  : {
         margin: 1,
     },
    
    popupContentButton: {
        background: 'white',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
        cursor: 'pointer',
        hover: {
            background: '#ccc',
        }
    },
    
    
    
  
    
}

export const keyframes = `
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
`;