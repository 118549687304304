import { COLORS } from '../util/constants';

export const styles = {
  paperChart: {
    padding: '10px',
    backgroundColor: COLORS.slateGray,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 3,
    height:'4.3%'
  },
  title: {
    color: COLORS.midnightBlue,
    marginBottom: 2,
  },
  
  chartContainer: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: COLORS.whiteSmoke,
    padding: 2,
    borderRadius: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:'75%',
  },
  statsContainer: {
    marginTop: 2,
  },
  totalPlayers: {
    color: COLORS.black,
    marginBottom: '5px',
     paddingLeft:'5px'
  },
  connectedPlayers: {
    color: COLORS.pieGreen,
    marginBottom: '3px',
    paddingLeft:'5px'
  },
  disconnectedPlayers: {
    color: COLORS.pieRed,
    marginBottom: '3px',
     paddingLeft:'5px'
  },
  disconnectedPlayers2: {
    color: COLORS.pieYellow,
    marginBottom: '3px',
     paddingLeft:'5px'
  },
};

export const getChartOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  cutout: '70%', // This creates the donut effect
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: COLORS.black,
        font: {
          weight: 'bold',
        },
      },
    },
  },
});

export const getPieChartOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  cutout: '0', // This creates the donut effect
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: COLORS.black,
        font: {
          weight: 'bold',
        },
      },
    },
  },
});