import PouchDB from 'pouchdb';

// Create a new database

export const summaryDB = new PouchDB('summaryData');
export const tickets_db = new PouchDB('tickets_db');

export const players_db = new PouchDB('players_db');
export const curr_checkin_db = new PouchDB('curr_checkin_db');

// You can also export other functions if needed
export const clearDatabase = async (dbName) => {
  // Function to clear the database
  
  const allDocs = await dbName.allDocs({ include_docs: true });
  const deletePromises = allDocs.rows.map(row => dbName.remove(row.doc));
  return Promise.all(deletePromises);
};

// You can also export other functions if needed
export const deleteDatabase = async (dbName) => {
  const dbNameString = dbName.name; // Get the database name
  try {
    const dbDestroy = await dbName.info();
    if (dbDestroy.auto_compaction)
      await dbName.destroy();
  } catch (error) {
    if (error.status !== 404) {
      console.error('Error destroying database:', error);
    }
  }
  return new PouchDB(dbNameString);
};

export const loadDataFromDB = async (dbName) => {
  try {
    //console.log(dbName)
    const result = await dbName.allDocs({ include_docs: true });
    //console.log(result)
    const docs = result.rows.map((row) => row.doc);
    //console.log(docs)
    return docs
    //setData(docs);
  } catch (error) {
    console.error("Error loading data from PouchDB:", error);
  }
};

export const safeUpdateSummary = async (data, dbName, maxAttempts = 3) => {
  await clearDatabase(players_db);
  await deleteDatabase(players_db);

  let attempts = 0;
  const docId = "summary";
  //console.log(data)
 
  data = JSON.parse(JSON.stringify(data));
  //console.log(typeof data)
  while (attempts < maxAttempts) {
    try {
      // Fetch the latest document revision.
      const existingDoc = await dbName.get("summary");
       // Check that the existing document is a valid object.
       if (typeof existingDoc !== "object" || existingDoc === null) {
        throw new Error("Invalid summary document");
      }
      //console.log("existingDoc")
      data._id = "summary";
      data._rev = existingDoc._rev;
      // Attempt to update the document.
      await dbName.put(data);
      console.log("Document updated successfully at:", data.lastUpdated);
      return;
    } catch (error) {
      if (error.status === 409) {
        // Document update conflict: retry after a short delay.
        attempts++;
        console.warn(`Update conflict encountered. Retry attempt ${attempts}`);
        await new Promise((resolve) => setTimeout(resolve, 100));
      } else if (error.status === 404) {
        // Document not found: create it.
        data._id = 'summary';
        await dbName.put(data);
        console.log("Document created successfully at:", data.lastUpdated);
        return;
      } else {
        // Re-throw unexpected errors.
        throw error;
      }
    }
  }
  throw new Error("Failed to update the document after multiple attempts");
}


