// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs'; // Import bcryptjs for password comparison
import { Box, Typography, Grid, Paper, TextField, Button } from '@mui/material';
import { styles } from '../css/login.styles.js';
import { COLORS1 } from '../util/constants';

const Login = ({onLogin}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    //const [isAuthenticated, setIsAuthenticated] = useState(false); // Manage authentication state

  

    const handleLogin = async () => {
        try {
            const response = await fetch('/usersEncrypted.json'); // Fetch the JSON file
            const users = await response.json(); // Parse the JSON data
           
            // Find the user by username
            const user = users.find(user => user.username === username);
            if (user) {
                // Compare the entered password with the hashed password
                const isMatch = await bcrypt.compare(password, user.password);
                if (isMatch) {
                    //setIsAuthenticated(true);
                    console.log(user)
                    sessionStorage.setItem('username', username); // Store username in sessionStorage
                    sessionStorage.setItem('userEmail', user.email);
                    onLogin();
                    navigate('/dashboard'); // Navigate to the form page with username
                } else {
                    setError('Invalid username or password'); // Set error message
                }
            } else {
                setError('User not found'); // Set error message
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setError('Failed to fetch user data');
        }
    };

    return (

        <Box sx={{ width: '100%' }}>
            <Box sx={styles.loginContainer}>
                <Box sx={styles.heading}>
                    <Typography variant="h5" gutterBottom>
                        Dashboard Login
                    </Typography>
                </Box>
                <Box sx={styles.loginForm}>
                    <Grid container spacing={2} sx={styles.loginFormGrid}>
                        <Grid item xs={12}>
                            <Paper sx={styles.loginFormPaper}>
                                <TextField
                                    label="Username"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    sx={styles.loginFormTextField}
                                />
                                <TextField
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    sx={styles.loginFormTextField}
                                />
                                <Button type="submit"
                                    variant="contained"
                                    sx={styles.loginFormButton}
                                    fullWidth onClick={handleLogin}>Login</Button>
                                {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error if any */}
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>


            </Box>
        </Box>

    );
};

export default Login;