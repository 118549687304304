import { COLORS,COLORS1 } from '../util/constants';

export const styles = {
  paper: {
    padding: '5px',
    backgroundColor: COLORS1.bgColor,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 3,
    height:'100%',
    width:'100%'
  },
  paperChart: {
    padding: '5px',
    backgroundColor: COLORS1.bgColor,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 3,
    height:'100%'
  },
  title: {
    color: COLORS.midnightBlue,
    marginBottom: 2,
  },
  container: {
    backgroundColor: COLORS.whiteSmoke,
    padding: '15px',
    minHeight: '100vh',
  },
  title: {
    color: COLORS.charcoal,
    marginBottom: '20px',
  },
  filtersContainer: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    paddingRight:'10px !important',
   
  },
  
  formControl: {
    marginRight: '15px',
    marginLeft: '15px',
    //width: '250px',
  },
  select: {
    backgroundColor: COLORS.whiteSmoke,
    '&:hover': { backgroundColor: COLORS.whiteSmoke },
    '& .MuiOutlinedInput-notchedOutline': { borderColor: COLORS.charcoal },
    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: COLORS.teal },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: COLORS.yellow },
  },
  inputLabel: {
    color: COLORS.charcoal,
    '&.Mui-focused': {
      color: COLORS.yellow,
    },
  },
  gridContainer: {
    height: 'calc(100vh - 200px)',
    
  },
  chartContainer: {
    height: '100%',
  },
  tableContainer: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: COLORS.grey,
    boxShadow: 3,
    marginTop: '1px',
   // overflowY: 'auto', 
    maxHeight: '415px',
    overflowX: 'auto'
    
   
  },
  table: {
    minWidth: 650,
    width: '100%',
    tableLayout: 'fixed'
    
  },
  tableHead: {
    '& .MuiTableCell-head': {
      backgroundColor: COLORS1.bgColor + ' !important',
    },
    top: 0, 
    background: 'white', 
    zIndex: 2 
  },
  tableHeadCell: {
    color: COLORS.whiteSmoke,
    whiteSpace: 'nowrap',
   
    width: '20%'
  },
  tableSortLabel: {
    color: COLORS.whiteSmoke + ' !important',
    '&.MuiTableSortLabel-active': {
      color: COLORS.whiteSmoke + ' !important',
    },
    '&:hover': {
      color: COLORS.lightGrey + ' !important',
    },
    '& .MuiTableSortLabel-icon': {
      color: COLORS.whiteSmoke + ' !important',
    },
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: COLORS1.beige,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: COLORS.whiteSmoke,
    },
    '&:hover': {
      backgroundColor: COLORS.hoverRed,
      '& .MuiTableCell-root': { color: COLORS.black },
    },
  },
  tableCell: {
    whiteSpace: 'wrap',
    color: COLORS.black,
    padding: '8px',
  },
  iconCell: {
    textAlign: 'center',
  },
  nameCell: {
    whiteSpace: 'normal', // Allow text to wrap
    wordWrap: 'break-word', // Break long words
    color: COLORS.black,
    padding: '8px',
    maxWidth: '150px',
    overflow: 'hidden'
  },
  connectedCell: {
    color: COLORS.seaGreen,
  },
  disconnectedCell: {
    color: COLORS.crimson,
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  errorMessage: {
    color: COLORS.crimson,
    textAlign: 'center',
    marginTop: 2,
  },
  playerTableGrid: {
    padding: '0px !important',
    height: '100%',
    width:'100%',

  }

};