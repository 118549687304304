import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { styles } from '../../css/players.styles';

function TicketsTable({ tickets, loading, error }) {


  //console.log(tickets.length)
  if (!Array.isArray(tickets) || tickets.length === 0) {
    return <Typography>No tickets available</Typography>;
  }

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;
  if (tickets.length === 0) return <Typography>No open tickets found in the last week.</Typography>;
  // const oneWeekAgo = new Date();
  // oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  // const openTicketsLastWeek = tickets.filter(
  //   ticket => new Date(ticket.createdTime) >= oneWeekAgo
  // );

  return (
    <TableContainer component={Paper}>
      <Table sx={styles.table} aria-label="tickets table">
        <TableHead sx={styles.tableHead} >
          <TableRow>
            <TableCell sx={styles.tableHeadCell}>Ticket No.</TableCell>
            <TableCell sx={styles.tableHeadCell}>Client Name</TableCell>
            <TableCell sx={styles.tableHeadCell}>Subject</TableCell>
            <TableCell sx={styles.tableHeadCell}>Agent</TableCell>
            <TableCell sx={styles.tableHeadCell}>Status</TableCell>
            {/*<TableCell sx={styles.tableHeadCell}>Email</TableCell>*/}
            <TableCell sx={styles.tableHeadCell}>Received Date Time </TableCell>
            <TableCell sx={styles.tableHeadCell}>Response Date Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map((ticket) => (

            <TableRow key={ticket.id || ticket._id} sx={styles.tableRow}>
              <TableCell sx={styles.tableCell}>{ticket.ticketNumber}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.clientName}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.subject}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.agentName}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.status}</TableCell>
              {/* <TableCell>{ticket.email}</TableCell>*/}
              <TableCell sx={styles.tableCell}>{ticket.createdTime}</TableCell>
              <TableCell sx={styles.tableCell}>{ticket.customerResponseTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TicketsTable;

