import React, { useState, useMemo, useEffect } from 'react';
import PlayersTab from './PlayersTab';
import PlayerFilters from './PlayerFilters';
import AllPlayersTab from './AllPlayersTab';
import ConnectionCharts from './ConnectionCharts';
import {
  Box,
  Paper,
  Typography,
  Grid,
  CircularProgress
} from '@mui/material';
import { styles } from '../../css/players.styles';
import { filterPlayers, calculateDaysOffline, getUniqueValues } from '../../util/dashboardUtil';
import LastSeen from './LastSeen';
//import GenieFlutter from '../GenieFlutter';

function PlayersDashboard({ players, loading, error, allFlag, currentTime }) {

  //const connectedPlayers = players.filter(player => player.connected);
  //const disconnectedPlayers = players.filter(player => !player.connected);
  const [sortConfig, setSortConfig] = useState({ key: 'daysOffline', direction: 'desc' });
  const [selectedOrgName, setSelectedOrgName] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [inputLists, setInputLists] = useState([]);
  const [selectedPlayerType, setSelectedPlayerType] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const [selectedOrgType, setSelectedOrgType] = useState('')
  const [selectedReseller, setSelectedReseller] = useState('')
  const [lastSeenPlayers, setLastSeenPlayers] = useState([]);
  const [header,setHeader]=useState('')

  const clientName = selectedOrgName

  // const { connectedPlayers, disconnectedPlayers } = players.reduce((acc, player) => {
  //   if (player.connected) {
  //     acc.connectedPlayers.push(player);
  //   } else {
  //     acc.disconnectedPlayers.push(player);
  //   }
  //   return acc;
  // }, { connectedPlayers: [], disconnectedPlayers: [] });
  
  

  // useEffect(() => {
  //   //console.log(players)
  //   const uniqueOrgNames = getUniqueValues(players, "orgName");
  //   //setOrgNames(uniqueOrgNames)
  //   const uniqueResellers = getUniqueValues(players, "reseller")
  //   const uniqueOrgTypes = getUniqueValues(players, "orgType")
  //   //const uniquePlayerTypes =  getUniqueValuesLabels(players, 'playerType')
  //   setInputLists({ orgNames: uniqueOrgNames, resellers: uniqueResellers, orgTypes: uniqueOrgTypes })
  // }, [players])




  const sortedFilteredPlayers = useMemo(() => {
    //console.log(selectedOrgName, selectedPlayerType, selectedTimezone )
    //console.log(players&&players.length)

    const filters = {
      orgName: selectedOrgName,
      playerType: selectedPlayerType,
      timezone: selectedTimezone,
      orgType: selectedOrgType,
      reseller: selectedReseller,
    };
    const filtered = filterPlayers(players, filters);
    // console.log(filtered)
    return filtered.sort((a, b) => {
      const daysOfflineA = a.isConnected ? 0 : calculateDaysOffline(a.lastSeen);
      const daysOfflineB = b.isConnected ? 0 : calculateDaysOffline(b.lastSeen);

      if (sortConfig.key === 'daysOffline') {
        return sortConfig.direction === 'asc' ? daysOfflineA - daysOfflineB : daysOfflineB - daysOfflineA;
      }
      return 0;
    });
  }, [players, selectedOrgName, selectedPlayerType, selectedTimezone, selectedOrgType, selectedReseller, sortConfig]);


  useEffect(() => {
    setFilteredPlayers(sortedFilteredPlayers);
    if(allFlag){
      setHeader('All Onsign Players Overview')
    }else{
      setHeader('Birmingham Players Overview')
    }
  }, [sortedFilteredPlayers]);

  //console.log(filteredPlayers)

  useEffect(() => {
    const playerTypes = sortedFilteredPlayers.map(player => player.playerType.type)
    const uniquePlayerTypes = [...new Set(playerTypes)]
    //console.log(uniquePlayerTypes)
    const uniqueValues = getUniqueValues(sortedFilteredPlayers, ["orgName", "reseller", "orgType", "timezone"]);
    //console.log(uniqueValues)
    setInputLists({ ...uniqueValues, playerTypes: uniquePlayerTypes });
  }, [sortedFilteredPlayers]);


  return (
    <div>

      <Box sx={styles.container}>
        {loading ? (
           <CircularProgress />
        ) : error ? (
          <Typography color="error" variant="h6">{error instanceof Error ? error.message : error}</Typography>
        ) : (
          <Grid>
            
            <Typography variant="h5" gutterBottom>
              {header}  {clientName ? `for ${clientName}` : ''}
            </Typography>

            <PlayerFilters
              inputLists={inputLists}
              players={players}
              allFlag={allFlag}
              filterProps={{
                selectedOrgName,
                setSelectedOrgName,
                selectedPlayerType,
                setSelectedPlayerType,
                selectedTimezone,
                setSelectedTimezone,
                selectedOrgType,
                setSelectedOrgType,
                selectedReseller,
                setSelectedReseller,
              }}
            />


            <Grid container spacing={3}>
              <Grid item xs={12} md={4.5}>

                {/*** allFlag ? <Paper sx={styles.paperChartGenie}><GenieFlutter styles={{ backgroundColor: "white" }} /> </Paper> : **/}
                <Paper sx={styles.paperChart}>
                  <ConnectionCharts
                    players={sortedFilteredPlayers}
                    allFlag={allFlag}
                  />
                </Paper>


              </Grid>

              <Grid item xs={12} md={7.5} >
                <Paper sx={styles.paper}>
                  {allFlag ?
                    <AllPlayersTab players={filteredPlayers} orgNames={inputLists.orgNames} loading={loading} error={error} allFlag={allFlag} /> :
                    <PlayersTab players={filteredPlayers} orgNames={inputLists.orgNames} loading={loading} error={error} allFlag={allFlag} />
                  }
                </Paper>
              </Grid>
            </Grid>

            <LastSeen players={filteredPlayers} setLastSeenPlayers={setLastSeenPlayers} currentTime={currentTime} />
          </Grid>
        )}
      </Box>

    </div>
  );
}

export default PlayersDashboard;