import { COLORS, COLORS1 } from '../util/constants';

export const styles = {
    loginContainer: {
        backgroundColor: COLORS1.bgColor,
    },

    heading: {
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: COLORS1.tabColor,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        overflow: 'hidden',
        padding: '5px',
        paddingLeft: '20px',
    },
    loginForm: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '100px',
    },
    loginFormGrid:{
        width: '500px', // Set a fixed width for the form
        height: '400px',
        padding: '16px',
        backgroundColor: COLORS.whiteSmoke,
        borderRadius: '8px',
        boxShadow: 2,
    },
    loginFormPaper:{
        padding: '20px',
    },
    loginFormButton:{
        marginTop: '20px',
        backgroundColor: COLORS1.tabColor,
    }
};
