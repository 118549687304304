export const timeZones = [
    {
        "value": ["America/New_York", "Canada/Eastern","US/Eastern"],
        "label": "EST/EDT"
    },
    {
        "value": ["America/Anchorage"],
        "label": "AKST/AKDT"
    },
    {
        "value": [ "America/Barbados"],
        "label": "AST/ADT"
    },
    {
        "value": ["America/Chicago", "US/Central"],
        "label": "CST/CDT"
    },
    {
        "value": ["America/Costa_Rica", "America/Mexico_City", "America/Regina"],
        "label": "CST [DST not observed]"
    },
    {
        "value": ["Canada/Mountain","America/Denver"],
        "label": "MST/MDT"
    },
    {
        "value": ["America/Los_Angeles", "America/Tijuana"],
        "label": "PST/PDT"
    },
    {
        "value": ["America/Phoenix"],
        "label": "MST [DST not observed]"
    },
    {
        "value": ["Asia/Karachi"],
        "label": "PKT"
    },
    {
        "value": ["Asia/Shanghai"],
        "label": "China ST"
    },
    {
        "value": ["Etc/UTC"],
        "label": "Etc/UTC"
    },
    {
        "value": ["Europe/London", "GMT"],
        "label": "GMT"
    },
]


