import React, { useMemo, useState } from 'react';
import { Pie, Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Paper, Typography, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { COLORS } from '../../util/constants';
import { styles, getChartOptions, getPieChartOptions } from '../../css/charts.styles';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const ConnectionCharts = ({ players, selectedOrgName,allFlag }) => {
  const [chartType, setChartType] = useState('doughnut'); // Default chart type

  const calculateDaysOffline = (lastSeen) => {
    const lastSeenDate = new Date(lastSeen);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - lastSeenDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const getConnectionStats = useMemo(() => {
    let filteredPlayers = players;

    if (selectedOrgName) {
      filteredPlayers = filteredPlayers.filter(player => player.orgName === selectedOrgName);
    }

    const totalPlayers = filteredPlayers.length;
    const connectedPlayers = filteredPlayers.filter(player => player.isConnected).length;
    const offlinePlayers = filteredPlayers.filter(player => !player.isConnected);
    const offlineMoreThanWeek = offlinePlayers.filter(player => !player.isConnected && calculateDaysOffline(player.lastSeen) > 7).length;
    const offlineWeekOrLess = offlinePlayers.filter(player => !player.isConnected && calculateDaysOffline(player.lastSeen) <= 7).length;

    return { totalPlayers, connectedPlayers,offlinePlayers, offlineMoreThanWeek, offlineWeekOrLess };
  }, [players, selectedOrgName]);

  const { totalPlayers, connectedPlayers,offlinePlayers, offlineMoreThanWeek, offlineWeekOrLess } = getConnectionStats;
  const labels = [];
  const data = [];
  if(allFlag){
    labels.push('Online', 'Offline')
    data.push(connectedPlayers, offlinePlayers.length)
  }else{
    labels.push('Online', 'Offline > 1 week', 'Offline ≤ 1 week');
    data.push(connectedPlayers, offlineMoreThanWeek, offlineWeekOrLess)
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: [COLORS.pieGreen, COLORS.pieRed, COLORS.pieYellow],
        borderColor: [COLORS.pieGreen, COLORS.pieRed, COLORS.pieYellow],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Players',
        data: data,
        backgroundColor: [COLORS.pieGreen, COLORS.pieRed, COLORS.pieYellow],
      },
    ],
  };

  const chartOptions = getChartOptions();
  const pieChartOptions = getPieChartOptions();


  return (
    <Paper elevation={3} sx={{ padding: 2, borderRadius: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ToggleButtonGroup
          value={chartType}
          exclusive
          onChange={(event, newType) => setChartType(newType)}
          aria-label="chart type"
          sx={{ marginBottom: 2, height: 15 }}
        >
          <ToggleButton value="doughnut" aria-label="doughnut chart">Doughnut</ToggleButton>
        { /** <ToggleButton value="pie" aria-label="pie chart">Pie</ToggleButton>
          <ToggleButton value="bar" aria-label="bar chart">Bar</ToggleButton> **/ }
        </ToggleButtonGroup>

        <Box sx={{ width: '100%', maxWidth: 600, height: 350 }}>
          {chartType === 'doughnut' && <Doughnut data={chartData} options={chartOptions} />}
          {chartType === 'pie' && <Pie data={chartData} options={pieChartOptions} />}
          {chartType === 'bar' && <Bar data={barChartData} options={chartOptions} />}
        </Box>
      </Box>
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        <Typography sx={styles.totalPlayers}>Total Players: {totalPlayers}</Typography>
        <Typography sx={styles.connectedPlayers}>Online Players: {connectedPlayers}</Typography>
        {allFlag ?
          <Typography sx={styles.disconnectedPlayers}>Offline Players: {offlinePlayers.length}</Typography> :
          <>
            <Typography sx={styles.disconnectedPlayers}>Offline > 1 week: {offlineMoreThanWeek}</Typography>
            <Typography sx={styles.disconnectedPlayers2}>Offline ≤ 1 week: {offlineWeekOrLess}</Typography>
          </>
        }
      </Box>
    </Paper>
  );
};

export default ConnectionCharts;