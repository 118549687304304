export const anamolies = {

    "7DCDym": { org: "Message Point Media" },
    "59CN80j": { org: "Message Point Media" },
    "lrCkM2N": { org: "Message Point Media" },
    "ydClX7Q": { org: "Message Point Media" },
    "ZBCNXvq": { org: "Message Point Media" },
    "kkC8Lq5": { org: "Message Point Media" },
    "qyClpD5": { org: "Message Point Media" },

    "GXCG1Ox": { org: "City of Bessemer" },
    "ydCGEM": { org: "City of Bessemer" },
    "joCNbv": { org: "City of Bessemer" },
    "b6CQ3e0": { org: "City of Bessemer" },
    
    
    "9eC2BX": { org: "BJCTA Max Transit" },
    "K7C5Gn": { org: "BJCTA Max Transit" },
    "9eC2BX": { org: "BJCTA Max Transit" },
    "xMC0Me": { org: "BJCTA Max Transit" },
    "6KC87k": { org: "BJCTA Max Transit" },
    "ZBC1bn": { org: "BJCTA Max Transit" },
    "gQCVPg": { org: "BJCTA Max Transit" },
    "VZCdKv": { org: "BJCTA Max Transit" },
    "OxCmQj": { org: "BJCTA Max Transit" },
    "eqClvO": { org: "BJCTA Max Transit" },

};

export const orgAnamolyNames = {
    one: "Message Point Media",
    two: "BJCTA",
    three: "City of Bessemer"
};