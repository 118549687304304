import React, { useState,useEffect } from 'react';
import { styles } from '../../css/popup.styles';
import { Grid, Typography, Box, Button, Table, TableHead, TableBody, TableCell, TableRow, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import MinimizeIcon from '@mui/icons-material/Minimize'; // Import Minimize icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import Maximize icon
import { getLocalTimeForTimezone, convertUtcToTimezone } from '../../util/dashboardUtil';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Import Copy icon


const Popup = ({  players, currentTime, onClose }) => {
    const [isMinimized, setIsMinimized] = useState(false); // State to track minimized status
    const nowOnlinePlayers = JSON.parse(sessionStorage.getItem('nowOnlinePlayers'));
    console.log(nowOnlinePlayers && nowOnlinePlayers.length > 0);
    // Handle minimize/maximize action
    const handleToggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    // Function to copy table content to clipboard
    const copyTableContent = () => {
        const tableContent = players.map(player => {
            return `${player.name} - ( ${player.orgName} )`;
        }).join('\n');

        navigator.clipboard.writeText(tableContent)
            .then(() => {
                alert('Table content copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    // Show notification and play sound when popup opens
    useEffect(() => {
        if (players && players.length > 0) {
            // Play sound
            const audio = new Audio('/notification.mpeg'); // Path to your sound file
            audio.play().catch(err => {
                console.error('Error playing sound:', err);
            });
        }
    }, [players]);

    

    return (
        <Box sx={styles.popup} className="popup">
            <div className="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={styles.popupHeader}>Online Players : {nowOnlinePlayers && nowOnlinePlayers.length}</Typography>
                <IconButton onClick={copyTableContent} size="small"> {/* Reduce icon size */}
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
                <div>
                    <IconButton onClick={handleToggleMinimize} size="small"> {/* Reduce icon size */}
                        {isMinimized ? <ExpandMoreIcon fontSize="small" /> : <MinimizeIcon fontSize="small" />} {/* Toggle between Minimize and Maximize icons */}
                    </IconButton>
                    <IconButton onClick={onClose} size="small"> {/* Reduce icon size */}
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
            {!isMinimized && (
                <Grid className="popup-content" sx={styles.popupContent}>
                    <Typography sx={styles.popupContentP}>{nowOnlinePlayers && nowOnlinePlayers.length} players have come online in the past 5 minutes @ {currentTime}.</Typography>
                    <Table>
                        <TableBody>
                            {nowOnlinePlayers && nowOnlinePlayers.map((player) => (
                                <TableRow key={player.id}>
                                    <TableCell>{player.name} - ( {player.orgName} )</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            )}
        </Box>
    );
};
export default Popup;