import React, { useState, useMemo, useEffect } from 'react';
import PlayersTab from './PlayersTab';
import PlayerFilters from './PlayerFilters';
import AllPlayersTab from './AllPlayersTab';
import ConnectionCharts from './ConnectionCharts';
import {
  Box,
  Paper,
  Typography,
  Grid,
} from '@mui/material';
import { styles } from '../../css/players.styles';
import { filterPlayers, calculateDaysOffline, getUniqueValues } from '../../util/dashboardUtil';
import LastSeen from './LastSeen';

function PlayersDashboard({ players, loading, error, allFlag, currentTime }) {

  const connectedPlayers = players.filter(player => player.connected);
  const disconnectedPlayers = players.filter(player => !player.connected);
  const [sortConfig, setSortConfig] = useState({ key: 'daysOffline', direction: 'desc' });
  const [selectedOrgName, setSelectedOrgName] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [orgNames, setOrgNames] = useState([]);
  const [selectedPlayerType, setSelectedPlayerType] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const [lastSeenPlayers, setLastSeenPlayers] = useState([]);

  const clientName = selectedOrgName

  useEffect(() => {
    const uniqueOrgNames = getUniqueValues(players, "orgName");
    setOrgNames(uniqueOrgNames)

  }, [players])
  
  const sortedFilteredPlayers = useMemo(() => {
    const filtered = filterPlayers(players, selectedOrgName, selectedPlayerType, selectedTimezone);
    
    return filtered.sort((a, b) => {
      const daysOfflineA = a.isConnected ? 0 : calculateDaysOffline(a.lastSeen);
      const daysOfflineB = b.isConnected ? 0 : calculateDaysOffline(b.lastSeen);

      if (sortConfig.key === 'daysOffline') {
        return sortConfig.direction === 'asc' ? daysOfflineA - daysOfflineB : daysOfflineB - daysOfflineA;
      }
      return 0;
    });
  }, [players, selectedOrgName, selectedPlayerType, selectedTimezone, sortConfig]);
  

  useEffect(() => {
    setFilteredPlayers(sortedFilteredPlayers);
  }, [sortedFilteredPlayers]);

  //console.log(filteredPlayers)

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <Box sx={styles.container}>


          <Typography variant="h5" gutterBottom>
            On-Site Players Overview {clientName? `for ${clientName}` : ''}
          </Typography>

          <PlayerFilters
            orgNames={orgNames}
            players={players}
            selectedOrgName={selectedOrgName}
            setSelectedOrgName={setSelectedOrgName}
            allFlag={allFlag}
            selectedPlayerType={selectedPlayerType}
            setSelectedPlayerType={setSelectedPlayerType}
            selectedTimezone={selectedTimezone}
            setSelectedTimezone={setSelectedTimezone}
          />


          <Grid container spacing={3}>
            <Grid item xs={12} md={4.5}>
              <Paper sx={styles.paperChart}>

                <ConnectionCharts
                  connectedPlayers={connectedPlayers}
                  disconnectedPlayers={disconnectedPlayers}
                  players={sortedFilteredPlayers}
                  allFlag={allFlag}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={7.5} >
              <Paper sx={styles.paper}>
                {allFlag ?
                  <AllPlayersTab players={filteredPlayers} orgNames={orgNames} loading={loading} error={error} allFlag={allFlag} /> :
                  <PlayersTab players={filteredPlayers} orgNames={orgNames} loading={loading} error={error} allFlag={allFlag} />
                }
              </Paper>
            </Grid>
          </Grid>
         
          <LastSeen players={filteredPlayers}   setLastSeenPlayers={setLastSeenPlayers} currentTime={currentTime} /> 
        </Box>
      )}
    </div>
  );
}

export default PlayersDashboard;