import React, { useState, useEffect } from 'react';
import DashboardOverview from './DashboardOverview';
import { getRecentTickets, getOfflinePlayers, getCurrentCheckin } from '../util/getdatafromapi';
import { loadDataFromDB, safeUpdateSummary } from '../localdb/PouchDB';
import { players_db, tickets_db, curr_checkin_db } from '../localdb/PouchDB'




function DashboardData() {
    const [tickets, setTickets] = useState(null)
    const [players, setPlayers] = useState(null)
    const [currCheckin, setCurrCheckin] = useState(null)
    const [loading,setLoading] = useState(true);
    const [offLine, setOffline] = useState(false)

    const refreshAPIData = async () => {
        console.log("data from api call ")
        const startTime = Date.now();
        try {
            const ticketDetails = await getRecentTickets();
            const playerDetails =  await getOfflinePlayers();
            const currCheckinDetails = await getCurrentCheckin();
            console.log("API fetch duration:", Date.now() - startTime);
          
            setTickets(ticketDetails)
            setPlayers(playerDetails)
            setCurrCheckin(currCheckinDetails)

            updatetoLocalDB(ticketDetails,playerDetails,currCheckinDetails)


        } catch (error) {
            console.error("Error fetching API data:", error);
            // Fallback to local DB if API fetch fails
            await loadData();
        }finally{
            setLoading(false)
        }
    }

    const loadData = async () => {
       
        // Attempt to load data from local DB
        try{
            const tickets = await loadDataFromDB(tickets_db);
            const players = await loadDataFromDB(players_db);
            const currCheckin = await loadDataFromDB(curr_checkin_db);
    
            // Check if data is available in local DB
            if (tickets[0] && players[0] && currCheckin[0]) {
                console.log("data from localdb")
                setTickets(tickets[0]);
                setPlayers(players[0]);
                setCurrCheckin(currCheckin[0]);
                setOffline(true)
            } else {
                // If data is not available, fetch from API
                await refreshAPIData();
            }
        }catch(err){
            console.log(err)
        }
        finally{
            setLoading(false)
        }
        
    };
    // const getDataFromLocal = async () => {

    //     const tickets = await loadDataFromDB(tickets_db)
    //     //console.log(tickets[0].recentTickets)
    //     setTickets(tickets[0])

    //     const players = await loadDataFromDB(players_db)
    //     setPlayers(players[0])
    //     const response = await loadDataFromDB(curr_checkin_db)
    //     console.log(response[0])
    //     setCurrCheckin(response[0])

    // };

    const updatetoLocalDB = async (tickets,players,currCheckin) => {
        console.log("inside update LDB",tickets)
    
        if (tickets && tickets.recentTickets.length > 0) {
          safeUpdateSummary(tickets, tickets_db).then(() => console.log("Tickets update successful"))
            .catch((err) => console.error("Tickets  update failed:", err));
        }
    
        if (players && players.offLinePlayers.length > 0) {
          safeUpdateSummary(players, players_db).then(() => console.log("Players update successful"))
            .catch((err) => console.error("Players update failed:", err));
        }
    
        if (currCheckin && currCheckin.name) {
          safeUpdateSummary(currCheckin, curr_checkin_db).then(() => console.log("Current Checkin update successful"))
            .catch((err) => console.error("Current Checkin update failed:", err));
        }

        console.log("exit update LDB")
    
      }

    // API refresh: Run immediately and then every 5 minutes.
    useEffect(() => {
        const initialLoad = async () => {
            await loadData(); // Load from local DB or API
        };
        initialLoad();

        const apiInterval = setInterval(refreshAPIData, 4 * 60 * 1000);
        return () => clearInterval(apiInterval);
    }, []);


    return (

    
            <DashboardOverview tickets={tickets} players={players} currCheckin={currCheckin} loading={loading} offLine= {offLine}/> 
            

    );
}



export default DashboardData;