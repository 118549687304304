import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import TapasIcon from '@mui/icons-material/Tapas';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FunctionsIcon from '@mui/icons-material/Functions';
import styles from '../../css/summary.styles';
import { COLORS } from '../../util/constants'

const statusRows = [
  { label: <><FunctionsIcon /> Total</>, key: "total" },
  { label: <><DepartureBoardIcon /> Mobile </>, key: "Mobile" },
  { label: <><ApartmentIcon /> Interior </>, key: "Interior" },
  { label: <><TapasIcon />Exterior</>, key: "Exterior" },
];
// Helper function to compute counts from the players list
const computePlayerCounts = (players) => {
  //console.log(players)
  if(players && players.length !== 0){
    const counts = {
      total: { total: players.total.count, Mobile: players.mobile.count, Interior: players.interior.count, Exterior: players.exterior.count },
      online: { total: players.total.connected, Mobile: players.mobile.connected, Interior: players.interior.connected, Exterior: players.exterior.connected },
      offline: { total: players.total.notConnected, Mobile: players.mobile.notConnected, Interior: players.interior.notConnected, Exterior: players.exterior.notConnected },
    };
    return counts;
  }else{
    return 0;
  }
  
};



const PlayerStatus = ({ players, currCheckin }) => {
  // Compute the counts using the helper function
  const counts = computePlayerCounts(players);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={1} alignItems="center">
        {/* Header Row */}
        <Grid item xs={3}>
          <Paper sx={{ p: 1, backgroundColor: "#e0e0e0" }}>
            <Typography variant="h6" align="center">
              Status
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ p: 1, backgroundColor: "#e0e0e0" }}>
            <Typography variant="h6" align="center">
              Total
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ p: 1, backgroundColor: "#e0e0e0" }}>
            <Typography variant="h6" align="center">
              Online
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ p: 1, backgroundColor: "#e0e0e0" }}>
            <Typography variant="h6" align="center">
              Offline
            </Typography>
          </Paper>
        </Grid>

        {/* Data Rows */}
        {statusRows && statusRows.map((row) => (
          <React.Fragment key={row.key}>
            {/* Label Column */}
            <Grid item xs={3}>
              <Paper sx={{ p: 1, }}>
                <Typography variant="subtitle1">{row.label}</Typography>
              </Paper>
            </Grid>
            {/* Total Column */}
            <Grid item xs={3}>
              <Paper sx={{ p: 1, backgroundColor: COLORS.pastelOrange }}>
                <Typography variant="subtitle1" align="center">
                  {counts !== 0 ? counts.total[row.key] : "-"}
                </Typography>
              </Paper>
            </Grid>
            {/* Online Column */}
            <Grid item xs={3}>
              <Paper sx={{ p: 1, backgroundColor: COLORS.mintGreen }} >
                <Typography variant="subtitle1" align="center" >
                  {counts !== 0 ? counts.online[row.key] : "-"}
                </Typography>
              </Paper>
            </Grid>
            {/* Offline Column */}
            <Grid item xs={3}>
              <Paper sx={{ p: 1, backgroundColor: COLORS.pastelRed }}>
                <Typography variant="subtitle1" align="center">
                  {counts !== 0 ? counts.offline[row.key] : "-"}
                </Typography>
              </Paper>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default PlayerStatus;
