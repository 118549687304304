import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Link, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../css/summary.styles";
import TicketsTable from "./Tickets/TicketsTable";
import PlayersTableForSummary from './Players/PlayersTableForSummary'
import { COLORS1, COLORS } from '../util/constants'
import PlayerStatus from './Players/PlayerStatus'
import { GENIE_BASE_URL } from '../util/constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DashboardOverview = ({ tickets, players, currCheckin, loading, offLine }) => {
  const navigate = useNavigate();
  // const [tickets, setTickets] = useState([])
  // const [players, setPlayers] = useState([])
  // const [currCheckin, setCurrCheckin] = useState([])
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const username = sessionStorage.getItem('userEmail');
  const token = sessionStorage.getItem('token')



  const appLink = `${GENIE_BASE_URL}?&username=${encodeURIComponent(username)}&token=${encodeURIComponent(token)}`;


  // const getDataFromLocal = async () => {

  //   const tickets = await loadDataFromDB(tickets_db)
  //   //console.log(tickets[0].recentTickets)
  //   setTickets(tickets[0])

  //   const players = await loadDataFromDB(players_db)
  //   setPlayers(players[0])
  //   const response = await loadDataFromDB(curr_checkin_db)
  //   console.log(response[0])
  //   setCurrCheckin(response[0])

  // };

  

  useEffect(() => {
    


  }, [tickets, players, currCheckin]);

  return (
    <Box sx={styles.container}>
      {/* Main Grid Layout */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid item sx={styles.mainGrid} xs={12} md={4}>

          <Grid container spacing={2} sx={styles.gridStyles}>
            <Grid item xs={12}  >
              <Box sx={styles.header}>
                <Box constainer spacing={2} sx={{ display: 'flex', alignItems: 'left' }}>
                  <img src={require('../assets/MPMLogo.png')} alt="Logo" style={{ height: '35px', marginRight: '10px' }} />

                  {currCheckin && currCheckin.name && (

                    <Typography sx={{ p: 1, textAlign: 'right', flexGrow: 1 }}>
                      <span style={{ color: COLORS.whiteSmoke, fontWeight: 'bold', marginRight: '8px' }}>
                        {currCheckin.name}
                      </span>
                      is currently available at {currCheckin.user}
                    </Typography>
                  )}
                </Box>


              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.gridStyles}>
            <Grid item xs={5} sx={styles.row1Grids} >
              <Box sx={styles.heading}>
                <Typography variant="h6" sx={styles.headingFont}>
                  OnSign Players' Live Status
                  {offLine && (
                  <Typography sx={{ fontSize: '0.8rem', textAlign: 'right', flexGrow: 1 }}>
                    Last updated @ {players && players.lastUpdated}
                  </Typography>
                  )}
                </Typography>
              </Box>
              <Box sx={styles.playerStatus}>
                <PlayerStatus players={players} currCheckin={currCheckin} />
              </Box>
            </Grid>
            <Grid item xs={7} sx={styles.row1Grids} >

              <Box sx={{ ...styles.heading, display: 'flex', alignItems: 'center' }}>

                <Typography variant="h6" sx={styles.headingFont}>
                  Recent Open Tickets  {/** tickets && new Date(tickets.lastUpdated).toLocaleString() */}
                  <span style={{ margin: '0 130px' }}></span> {/* Space */}
                </Typography>
                <Typography variant="h6" >
                  Open Tickets:{tickets && tickets.openNum}
                  <span style={{ margin: '0 10px' }}></span> {/* Space */}
                </Typography>
                <Typography variant="h6">
                  Closed Tickets:{tickets && tickets.closedNum}
                  <span style={{ margin: '0 10px' }}></span> {/* Space */}
                </Typography>
              </Box>
              <Box sx={styles.table}> {/* Add margin-top for spacing */}
                <TicketsTable tickets={tickets && tickets.recentTickets} flag={true} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4} sx={{ ...styles.gridStyles, paddingTop: '10px', paddingBottom: '10px' }}>
            <Grid item xs={3.5}>
              <Box sx={styles.heading}>
                <Typography variant="h6" sx={styles.headingFont}>
                  Recently Offline Players
                </Typography>
              </Box>
              <Box sx={styles.table}>
                <PlayersTableForSummary players={players && players.offLinePlayers} type='All' />
              </Box>
            </Grid>
            <Grid item xs={4} >
              <Box sx={styles.heading}>
                <Typography variant="h6" sx={styles.headingFont}>
                  Recently Offline Fully Managed Players
                </Typography>
              </Box>
              <Box sx={styles.table}>
                <PlayersTableForSummary players={players && players.fullyManagedOffline} type='Fully-Managed' />
              </Box>
            </Grid>
            <Grid item xs={4.5}>
              <Box sx={styles.table}>
                {/* First image with text to its right */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box>
                    <img
                      src={require('../assets/BurnIns.png')}
                      alt="BurnIns"
                      style={{ height: '180px', marginRight: '10px' }}
                    />
                  </Box>

                  <Box sx={styles.qrCodeText}>
                    <ArrowBackIcon sx={{ marginRight: '5px' }} />
                    <Typography variant="h6" sx={styles.headingFont}>
                      Scan to access the latest burn-in reports
                    </Typography>
                  </Box>

                </Box>



              </Box>
            </Grid>
            {/***
            * <iframe
                        src={appLink}
                        style={{ width: '100%', height: '350px', border: 'none' }} // Adjust height as needed
                        title="Embedded App"
                    />
            */}

          </Grid>


          {/* "View More" Link to Login Page */}
          <Box sx={styles.linkBox}>
            <Link component="button" onClick={() => navigate("/login")} sx={styles.link}>
              🔗 Click here to log in for more details
            </Link>
          </Box>
        </Grid>)}
    </Box>

  );
};

export default DashboardOverview;
