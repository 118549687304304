
import { COLORS,COLORS1 } from '../util/constants';

export const styles = {
    paperClasses: {
       p: 2,
        display: 'flex', 
        flexDirection: 'column',
        '&:hover': {
            backgroundColor: COLORS1.tabSelected,
            cursor: 'pointer',
            
        }

    }
}