import React, { useState } from 'react';
import '../../css/modal.css';
import { COLORS, COLORS1 } from '../../util/constants';

import { calculateDaysOffline, getLocalTimeForTimezone, convertUtcToTimezone, checkWeekdays, getPlayerIcon } from '../../util/dashboardUtil';

const Modal = ({ isOpen, onClose, player }) => {
    //console.log(player)
    if (!isOpen) return null;

    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    console.log(player.workingDays === undefined ? player.workingDays : player.workingDays.startTime)
    const diffDays = player.isConnected ? 0 : calculateDaysOffline(player.lastSeen)
    // Determine the background color based on connection status
    const getBackgroundColor = () => {

        if (player.isConnected) {
            return COLORS.pieGreen; // Green for connected players
        } else if (diffDays < 7) {
            return COLORS.pieYellow; // Yellow for disconnected less than a week
        } else {
            return COLORS.pieRed; // Red for disconnected more than a week
        }
    };
    //const workingDays = checkWeekdays(player);

    return (
        <div className="modal">
            <div className="modal-content" style={{ backgroundColor: COLORS1.altRowBG }}>
                <span className="close" onClick={onClose}>&times;</span>
                <div className="modal-header" style={{ backgroundColor: getBackgroundColor() }}>
                    <h3 style={{ color: COLORS.whiteSmoke }}>{player.name} {player.playerType.iconPath} </h3>
                    <h4>
                        {player.isConnected ? 'Live' : 'Disconnected  ' + diffDays
                            + ' days ago'}
                    </h4>

                </div>
                <div>
                    <p><strong>System Version: </strong> {player.osVersion}</p>
                    <p><strong>Player Version: </strong>{player.version}</p>
                    <p><strong>Device Model: </strong>{player.model}</p>
                    <p><strong>Network: </strong>{player.network === null ? '' : player.network[0].name}</p>
                    <p><strong>Local Time: </strong>{getLocalTimeForTimezone(player.timezone)}</p>
                    <p><strong>Last Seen: </strong>{convertUtcToTimezone(player.timezone, player.lastSeen)}</p>
                    <p><strong>Server Content Change: </strong>{convertUtcToTimezone(player.timezone, player.lastUpdate)}</p>
                    <p><strong>Working Hours: </strong></p>
                    {player.workingDays !== '' ? (
                        <table style={{ fontSize: '12px', borderCollapse: 'collapse', width: '100%' }}> {/* Adjust the font size and add border styles */}
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid black' }}>Day</th>
                                    {weekdays.map((day, index) => (
                                        <th style={{ border: '1px solid black' }} key={index}>{day}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: '1px solid black' }}><strong>Start Time</strong></td>
                                    {player.workingDays ? player.workingDays.startTime.map((startTime, index) => (
                                        <td style={{ border: '1px solid black' }} key={index}>{startTime || 'Non-Working'}</td> // Display start time or 'N/A'
                                    )) : ''}
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black' }}><strong>End Time</strong></td> {/* Make End Time bold */}
                                    {player.workingDays !== '' ? player.workingDays.endTime.map((endTime, index) => (
                                        <td style={{ border: '1px solid black' }} key={index}>{endTime || 'Non-Working'}</td> // Display end time or 'N/A'
                                    )) : ''}
                                </tr>
                            </tbody>
                        </table>) : <p>No working hours are set</p>}
                    <p><strong>Last IP: </strong>{player.network === null ? '' : player.network[0].inet}</p>
                </div>
            </div>
        </div>
    );
};

export default Modal;