import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Paper, Grid, CircularProgress } from '@mui/material';
import TicketsTable from './TicketsTable';
import TicketFilters from './TicketFilters';
import { filterTickets } from '../../util/dashboardUtil';


function TicketsDashboard({ tickets, loading, error }) {
  //console.log(tickets.openTickets)
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [clientFilter, setClientFilter] = useState('');
  const [agentFilter, setAgentFilter] = useState('');
  //console.log(tickets)
  const sortTickets = useMemo(() => {
    const filtered = filterTickets(tickets.openTickets, clientFilter, agentFilter);
    return filtered;
  }, [tickets.openTickets, clientFilter, agentFilter]);
  //console.log(sortTickets)

  useEffect(() => {
    setFilteredTickets(sortTickets);
  }, [sortTickets]);


  return (
    <Paper sx={{ p: 2, }}>
      <Typography variant="h4" gutterBottom>
        Tickets Overview
      </Typography>
      {tickets ?
        <Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">Open Tickets</Typography>
                <Typography variant="h3">{tickets.openTickets.length}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">Closed Tickets</Typography>
                <Typography variant="h3">{tickets.closedTickets.length}</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <TicketFilters
                tickets={filteredTickets}
                clientFilter={clientFilter}
                setClientFilter={setClientFilter}
                agentFilter={agentFilter}
                setAgentFilter={setAgentFilter}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ pl: 3 }}>
              Open Tickets from the Last Week
            </Typography>
            <Grid item xs={12}>
              {loading ? (
                <CircularProgress />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : (
                <TicketsTable tickets={filteredTickets} loading={loading} error={error} />
              )}
            </Grid>
          </Grid>
        </Grid>

        : <Typography>No tickets found</Typography>

      }


    </Paper>
  );
}

export default TicketsDashboard;